<template>
  <v-text-field v-on="$listeners" @input="onInput" v-bind="$attrs" :type="type">
    <template v-slot:append>
      <v-icon @mousedown="changeType" @mouseup="changeType">{{ icon }}</v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'PasswordInput',
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    type() {
      return this.visible ? 'text' : 'password'
    },
    icon() {
      return this.visible ? 'mdi-eye' : 'mdi-eye-off'
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
    changeType() {
      this.visible = !this.visible
    },
  },
}
</script>

<style scoped></style>
