<template>
  <v-main class="bg-image">
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <slot />
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'ServiceLayout',
}
</script>

<style scoped lang="scss">
.bg-image {
  background: url('../../src/assets/img/bg.png') no-repeat center top/cover;
}
</style>
